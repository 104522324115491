import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
  updateDoc,
  doc,
} from 'firebase/firestore';

import {
  snapshotToTyped,
  type Cycle,
  type Order,
  type ProductSurveyAnswers,
} from '@tn/shared';
import { defineStore } from 'pinia';

export const useReviewsStore = defineStore('reviews', () => {
  const db = useFirestore();
  const user = useCurrentUser();
  const cyclesStore = useCyclesStore();
  const ordersStore = useOrdersStore();
  const now = useNow();

  const reviewsRef = computed(() => {
    if (!user.value) {
      return null;
    }

    return query(
      collection(db, 'productSurveyAnswers'),
      where('uid', '==', user.value.uid)
    );
  });

  const reviews = useCollection<ProductSurveyAnswers>(reviewsRef, {
    ssrKey: 'user-reviews',
  });

  const isLoading = computed(() => {
    return reviews.pending.value;
  });

  const pendingReviews = computed(() => {
    if (!reviews || !ordersStore.orders || !cyclesStore.currentCycle) {
      return null;
    }

    return ordersStore.orders.filter((order) => {
      // review cycle not open
      if (
        !cyclesStore.currentCycle ||
        now.value < cyclesStore.currentCycle.reviewStartDate
      )
        return false;

      const isFromCurrentCycle =
        order.createdAtTimeMs >= cyclesStore.currentCycle.startDate &&
        order.createdAtTimeMs <= cyclesStore.currentCycle.endDate;
      // Only reviews from current cycle can be pending
      if (!isFromCurrentCycle) return false;

      const orderReview = reviews.value.find((r) => r.orderId === order.id);
      // review is completed
      if (orderReview?.completedAtTimeMs) return false;

      return true;
    });
  });

  const pendingReviewsAmount = computed(() => pendingReviews.value?.length);

  const currentCycleExpectedReviewsAmount = computed(() => {
    if (!ordersStore.orders || !cyclesStore.currentCycle) {
      return null;
    }

    return ordersStore.currentCycleOrders.length;
  });

  const currentCyclereviews = computed(() => {
    if (!reviews || !cyclesStore.currentCycle) {
      return null;
    }

    return reviews.value.filter((review) => {
      return (
        !!review.completedAtTimeMs &&
        cyclesStore.currentCycle &&
        review.createdAtTimeMs >= cyclesStore.currentCycle.startDate &&
        review.createdAtTimeMs <= cyclesStore.currentCycle.endDate
      );
    });
  });

  const canStartSurvey = async (productId: string) => {
    const _user = await getCurrentUser();
    const uid = _user?.uid as string;

    if (!uid) return false;

    const ordersSnap = await getDocs(
      query(
        collection(db, 'orders'),
        where('uid', '==', uid),
        where(`products.${productId}.id`, '==', productId),
        orderBy('createdAtTimeMs', 'desc'),
        limit(1)
      )
    );

    if (!ordersSnap.docs.length) return false;

    const cycleSnap = await getDocs(
      query(
        collection(db, 'cycles'),
        where('startDate', '<=', now.value),
        where('endDate', '>=', now.value),
        orderBy('startDate', 'desc'),
        limit(1)
      )
    );

    if (!cycleSnap.docs.length) return false;

    const order = snapshotToTyped<Order>(ordersSnap.docs[0]);
    const currentCycle = snapshotToTyped<Cycle>(cycleSnap.docs[0]);

    return (
      order.createdAtTimeMs >= currentCycle.startDate &&
      order.createdAtTimeMs <= currentCycle.endDate &&
      now.value >= currentCycle.reviewStartDate
    );
  };

  const currentReviewedOrder = (orderId: string) =>
    ordersStore.orders.find((order) => order.id === orderId);

  const saveReviewAnswer = async ({
    id,
    questionId,
    answer,
  }: {
    id: string;
    questionId: string;
    answer: string | number | boolean | string[] | undefined;
  }) => {
    const reviewDoc = doc(db, 'productSurveyAnswers', id);

    try {
      await updateDoc(reviewDoc, { [`answers.${questionId}`]: answer });
    } catch (error) {
      console.error(
        `Error saving review answer for review ${reviewDoc} and question ${questionId}`
      );
      throw error;
    }
  };

  return {
    reviews,
    pendingReviewsAmount,
    currentCycleExpectedReviewsAmount,
    currentCyclereviews,
    isLoading,
    canStartSurvey,
    currentReviewedOrder,
    saveReviewAnswer,
    pendingReviews,
  };
});
