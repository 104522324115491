import { collection, query, where } from 'firebase/firestore';

import type { Order } from '@tn/shared';
import { defineStore } from 'pinia';

export const useOrdersStore = defineStore('orders', () => {
  const db = useFirestore();
  const user = useCurrentUser();
  const cyclesStore = useCyclesStore();
  const reviewsStore = useReviewsStore();

  const ordersRef = computed(() => {
    if (!user.value) {
      return null;
    }

    return query(collection(db, 'orders'), where('uid', '==', user.value.uid));
  });

  const orders = useCollection<Order>(ordersRef, {
    ssrKey: 'user-orders',
  });

  const isLoading = computed(() => orders.pending.value);

  const currentCycleOrders = computed(() =>
    orders.value.filter((order) => {
      return (
        cyclesStore.currentCycle &&
        order.createdAtTimeMs >= cyclesStore.currentCycle.startDate &&
        order.createdAtTimeMs <= cyclesStore.currentCycle.endDate
      );
    })
  );

  const hasOrderedProduct = computed(() => (productId: string): boolean => {
    if (!orders) {
      return false;
    }

    return orders.value.some((order) => !!order.products?.[productId]);
  });

  const canOrderProduct = computed(() => (productId: string) => {
    if (!orders) {
      return true;
    }

    const productOrders = orders.value
      .filter((order) => order.products[productId] !== undefined)
      .sort((a, b) => b.createdAtTimeMs - a.createdAtTimeMs);

    const latestOrder = productOrders[0];

    if (!latestOrder) {
      return true;
    }

    const reportedReviewForOrder = reviewsStore.reviews.find(
      (review) =>
        review.orderId === latestOrder.id &&
        review.survey.isReport &&
        review.completedAtTimeMs
    );

    return !!reportedReviewForOrder;
  });

  return {
    orders,
    currentCycleOrders,
    hasOrderedProduct,
    isLoading,
    canOrderProduct,
  };
});
